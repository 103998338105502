<template>
    <div class="chat">
        <ChatHeader :user="user"/>
        <slot></slot>
    </div>
</template>

<script>
import ChatHeader from '@/components/ChatHeader.vue';

export default {
    name: 'ChatTemplate',

    props: ['user'],

    components: {
      ChatHeader
    },
}
</script>
