<template>
    <div class="chat__header chat__block">
        <div class="input-control w-100 h-100">
            <i class="icon-back" @click="$root.open=true"></i>

            <template v-if="user">
                <div class="chat__card">
                    <div class="chat__user">
                        <i :class="user.icon"></i>
                    </div>
                    <div class="chat__info">
                        <h2 class="chat__info-title link">{{ user.name }}</h2>
                        <p class="chat__info-text">{{ user.info }}</p>
                    </div>
                </div>

                <i class="icon-search"></i>
                <i class="icon-dots"></i>
            </template>
            
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChatHeader',

    props: ['user']
}
</script>
