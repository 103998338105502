import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/manager",
        name: "Manager",
        // route level code-splitting
        // this generates a separate chunk (manager.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "manager" */ "../views/Manager.vue"),
        meta: { name: "@diskbot", info: "👨‍💻  бот глазами менеджера", icon: "icon-bot" },
    },

    {
        path: "/driver",
        name: "Driver",
        component: () => import(/* webpackChunkName: "driver" */ "../views/Driver.vue"),
        meta: { name: "@diskbot", info: "🚗  бот глазами водителя", icon: "icon-bot2" },
    },


    {
        path: "/:pathMatch(.*)*",
        component: Home,
    },
];

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
