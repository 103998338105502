<template>
    <ChatTemplate>
      <div class="chat__body h-100">
        <div class="home" title="Выберите, кому хотели бы написать"></div>
      </div>
    </ChatTemplate>
</template>

<script>
import ChatTemplate from '@/components/ChatTemplate.vue';

export default {
  name: 'Home',

  components: {
    ChatTemplate
  },

  mounted() {
      localStorage.clear();
  },
}
</script>

<style scoped>
.home {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.home:before {
  content: attr(title);
}
</style>
